<template src="./PcrResultList.html">
</template>
<script>


    export default {
        name: "PcrResultList",
        components: {
        },
        created() {
            this.getPcrResults();
        },

        computed: {
        },

        data: () => ({
            headers: [
                { text: "رقم العينة", value: "sampleId" },
                { text: "نوع العينة", value: "sampleType" },
                { text: "تاريخ الاختبار", value: "dateTested" },
                { text: "المصدر", value: "labNameAr" },
                { text: "النتيجة", value: "result" },
                { text: "الطباعة", value: "actions" },
            ],
            pcrResults: [],

        }),

        methods: {
            getPcrResults() {
                this.$http.getPcrResults()
                    .then(res => {
                        let data = res.data;
                        this.pcrResults = data.pcrResponse.pcrList;
                    })
                    .catch(err => {
                        this.$store.commit(
                            "snackbar/show",
                            { message: err.response.data.msg, icon: "mdi-alert-circle-outline", type: "error" }
                        )
                    });
            },

            printPcr(item) {
                window.open(item.url, "_blank");
            }
        },
    };
</script>

<style lang="scss">
</style>