var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-parallax',{attrs:{"dark":"","height":"200","contain":"","src":require("../../../assets/imgs/covid-vaccination-manufacturing-surge-capacity-sars-cov-2.jpg")}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h1',{staticClass:"display-1 font-weight-thin mb-4"},[_vm._v(" نتائج تحليل PCR ")])])],1)],1),_c('v-row',{staticClass:"mt-8",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"sm":"12","md":"10","lg":"8"}},[_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.pcrResults,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.result",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm._f("pcrColor")(item.result),"small":""}},[_vm._v(" "+_vm._s(_vm._f("pcrResult")(item.result))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.printPcr(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"info"}},[_vm._v("mdi-printer-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("طباعة")])])]}}])})],1)])],1),_c('br'),_c('br'),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }